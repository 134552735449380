var baseToolTip = require('core/components/toolTip');

module.exports = function () {
    baseToolTip();

    $('[data-toggle="tooltip"]').on('focus', function () {
        $(this).tooltip('show');
    }).on('blur', function () {
        $(this).tooltip('hide');
    });

    $('body').on('tooltip:init', function () {
        $('[data-toggle="tooltip"]').on('focus', function () {
            $(this).tooltip('show');
        }).on('blur', function () {
            $(this).tooltip('hide');
        });
    })
};