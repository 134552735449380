var main = require('integrations/main');


main.baseFiles.footer = require('./components/footer');
main.baseFiles.header = require('./components/header');
main.baseFiles.quickview = require('./product/quickView');
main.baseFiles.spinner = require('./components/spinner');
main.baseFiles.base = require('./product/base');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.cart = require('./cart');
main.baseFiles.toolTip = require('./components/toolTip');

module.exports = main;
